import { HttpStatusCode } from "@solidjs/start";
import { useEventsContext } from "~/contexts/EventsContext";
import { useModalFormContext } from "~/contexts/ModalFormContext";
import imgProxy from "~/utils/imgproxy";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";

import "~/css/404.css";
import { urlRs } from "~/utils/url";

export default function PageNotFound() {
  const [viewPortState] = useViewportSizeContext();
  const [, { sendShowEvent }] = useEventsContext();
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();

  return (
    <>
      <HttpStatusCode code={404} />

      <article class="error-page" data-ga-zone="in-page">
        <picture class="full-visual">
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/error-404.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/error-404.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/error-404.jpg",
              "size:1067:600/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 376px)"
            srcset={imgProxy(
              "/images/error-404.jpg",
              "size:750:422/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 375px)"
            srcset={imgProxy(
              "/images/error-404.jpg",
              "size:533:300/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              "/images/error-404.jpg",
              `size:${viewPortState.viewPortIsMoreThan768 ? "1366:768" : "375:667"}/resizing_type:fill`,
            )}
            alt=""
            height={viewPortState.viewPortIsMoreThan768 ? 768 : 300}
            width={viewPortState.viewPortIsMoreThan768 ? 1366 : 533}
            loading="lazy"
          />
        </picture>
        <div class="outer-content-part">
          <div class="content-part">
            <div data-test="introduction">
              <h1>La page est introuvable</h1>
              <p>
                Erreur 404. La page ou le fichier est introuvable. Nous vous
                invitons à retourner à l’accueil ou nous contacter. Nos
                conseillers répondront à toutes vos demandes dans les plus brefs
                délais.
              </p>
            </div>
            <div class="buttons">
              <a
                href={urlRs("search", "/programme-immobilier-neuf/")}
                class="btn btn-outline"
                data-test="btn-search"
              >
                Voir nos logements neufs
              </a>
              <button
                type="button"
                class="btn"
                onClick={(e) => {
                  sendShowEvent(`contact-us`, e);
                  openModalForm!("contact-us");
                  setModalFormHeadline!("Contact");
                }}
                data-test="cta-contact-us"
              >
                Nous contacter
              </button>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
